<template>
  <v-container fluid class="pt-0 fill-height">
    <MainModal
        :main="{ component: 'RemovalItems', title: 'Зняття за ненадані послуги' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_removal)"
        :modal="show_removal_dialog"
        max-width="900"
        @updateItemModal="removalUpdateItemModal"
    />
    <v-row justify="center" style="height: 100%; display: flex; flex-direction: column; flex-wrap: nowrap">
      <v-col cols="12" style="flex: 0">
        <v-card>
          <v-toolbar tile elevation="0" outlined dense style="border: none"
                     :height="$vuetify.breakpoint.xs ? 90 : 54">
            <template v-if="$vuetify.breakpoint.smAndUp">
              <v-toolbar-title class="font-weight-medium" style="font-size: 1.1rem">
                Перелік зняття
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items class="shrink">
                <v-row class="align-center">
                  <v-tooltip bottom color="success">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn @click="openRemovalCreateDialog"
                             v-bind="attrs"
                             v-on="on"
                             depressed
                             class="grey lighten-4 mr-2">
                        <v-icon left>mdi-plus</v-icon>
                        Створити
                      </v-btn>
                    </template>
                    <span>Створити нове зняття за ненадані послуги</span>
                  </v-tooltip>
                </v-row>
              </v-toolbar-items>
            </template>
            <template v-else>
              <div style="width: 100%">
                <div class="d-flex mb-2">
                  <v-toolbar-title class="font-weight-medium" style="font-size: 1.3rem">
                    Перелік зняття
                  </v-toolbar-title>
                </div>
                <div class="d-flex">
                  <v-spacer></v-spacer>
                  <v-btn @click="openRemovalCreateDialog"
                         depressed
                         class="grey lighten-4 mr-2">
                    <v-icon left>mdi-plus</v-icon>
                    Створити
                  </v-btn>
                </div>
              </div>
            </template>
          </v-toolbar>
        </v-card>
      </v-col>
      <v-col cols="12" style="flex: 1">
        <template v-if="items.length">
          <v-card
              v-for="(item, idx) in items" :key="idx" tile elevation="2"
              class="mb-2 d-flex flex-wrap flex-row row-card"
              style="border-left: 3px solid #5bb55f"
              @click.stop="onRemovalItemClick(item)"
          >
            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0' : ''">
              <div>Дата створення</div>
              <div>{{ item.create_date | formatDate('DD.MM.YYYY HH:mm:ss') }}</div>
            </v-col>
            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
              <div>Послуга</div>
              <div class="d-flex">
                <div>
                  <v-icon size="20" class="mr-2" :color="getAccountIcon(item.service_icon, 'color')">
                    {{ getAccountIcon(item.service_icon, 'icon') }}
                  </v-icon>
                </div>
                <div class="font-weight-medium">
                  {{ item.service_name }}
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="1" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
              <div>По статтях</div>
              <div>
                <v-simple-checkbox :ripple="false" :value="item.removal_by_item"/>
              </div>
            </v-col>
            <v-col cols="12" md="1" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
              <div>Постійне</div>
              <div>
                <v-simple-checkbox :ripple="false" :value="item.always_removal"/>
              </div>
            </v-col>
            <v-col cols="12" md="2" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
              <div>Період</div>
              <div v-if="!item.date_end">
                {{ item.date_start | formatDate }}
              </div>
              <div v-else>
                {{ item.date_start | formatDate }} - {{ item.date_end | formatDate }}
              </div>
            </v-col>
            <v-col cols="12" md="4" :class="$vuetify.breakpoint.xs ? 'pb-0 pt-2' : ''">
              <div>Фільтр</div>
              <div>{{ item.filter_represent }}</div>
            </v-col>
          </v-card>
        </template>
        <template v-else>
          <v-card style="height: 100%; display: flex; align-items: center; justify-content: center">
            <div class="wrapper align-center text-center">
              <v-icon size="200" color="success" style="opacity: .24">mdi-clock</v-icon>
              <div class="title grey--text text--darken-2 px-4">Документи для відображення відсутні <br> Спершу створіть
                документ
              </div>
            </div>
          </v-card>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {getAccountIcon} from "@/utils/icons";
import {FETCH_REMOVAL_ITEMS_ALL} from "@/store/actions/removal";

export default {
  name: "Removal",
  components: {
    MainModal: () => import("@/components/modal/MainModal")
  },
  computed: {
    ...mapGetters({
      items: 'getRemovalHeaders',
      legacy: 'isLegacy'
    })
  },
  data() {
    return {
      selected_removal: {},
      show_removal_dialog: false
    }
  },
  methods: {
    getAccountIcon,
    removalUpdateItemModal() {
      this.show_removal_dialog = false
      this.selected_removal = {}
    },
    openRemovalCreateDialog() {
      this.selected_removal = {is_legacy: this.legacy}
      this.show_removal_dialog = true
    },
    onRemovalItemClick(payload) {
      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.date_end = local_payload.date_end || null
      local_payload.tariff_group_type_id = local_payload.tariff_group_type_id || null
      local_payload.floor_filter = local_payload.floor_filter || false
      local_payload.entrance_filter = local_payload.entrance_filter || false

      this.selected_removal = local_payload
      this.show_removal_dialog = true
    },
  },
  created() {
    this.$store.dispatch(FETCH_REMOVAL_ITEMS_ALL, this.legacy)
  }
}
</script>

<style scoped lang="scss">
.row-card {
  div > div:nth-child(1) {
    font-size: .68rem;
    font-weight: 400;
    color: #757575
  }

  div > div:nth-child(2) {
    font-size: .84rem;
    color: #4a4a4a;

    span:nth-child(1) {
      font-weight: 500;
      color: #2a9d2f;
    }

    span:nth-child(2) {
      font-weight: 500;
      color: #2d2d2d;
    }
  }
}
</style>